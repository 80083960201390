<template>
  <div>
    <div class="wrap">
      <div class="top-area">
        <div class="title">Winex Guide</div>
      </div>
    </div>
    <div class="notice-detail-wrap">
      <div class="notice-content-wrap">
        <!-- Step1 -->
        <div v-if="no === 1">
          <div class="notice-subject-wrap">
            <div class="notice-subject">
              Step 1 메타마스크 생성 및 세팅
              <p>* 원활한 사용을 위해 PC에서 접속을 권장합니다.</p>
            </div>
            <div class="url-copy-btn" @click="urlCopyBtn">
              <img src="~@/assets/images/user-guide/ic_link.png"/> URL 복사
            </div>
            <div class="url-copy-btn-mo" @click="urlCopyBtn">
              <img src="~@/assets/images/user-guide/ic_link.png"/>
            </div>
          </div>
          <div class="user-guide-content-wrap">
            <div class="user-guide-start">
              현재 하려는 과정은 거래소에서 이더리움을 산 후 나의 메타마스크로 보내고, 이더리움을 폴리곤USDC로 환전하여 와넥스에서 와인 NFT를 구매하는 것입니다.
              <br/><br/>
              비유를 하자면 은행에서 유로를 사서 나의 계좌로 보내고, 유로를 달러로 바꿔서 아마존에서 상품을 구매하는 것과 유사한 과정입니다.
              <br/><br/>
              한국 은행, 미국 은행 계좌가 하나도 없는 사람이 해외 직구를 해외 송금으로 하려고 하면 처음에 어려운 과정이 있지만, 계좌를 개설하는 것은 한번만 하면 되기에 두번째부턴 그리 어렵지 않습니다.순서는 업비트,케이뱅크 가입, 업비트-메타마스크 연결, 케이뱅크에 입금후 업비트 원화 계좌에 입금(이때 이더리움을 사지 말고 출금 대기시간인 72시간을 기다려야 합니다. 만약 그 전에 사게 되면 이더리움 가격 등락으로 인한 이익 또는 손해를 보게 될 수 있습니다.), 72시간이 지난 후 이더리움 구매 및 메타마스크로 출금, 메타마스크에서 환전 사이트인 체인질리나 체인지나우를 이용하여 USDC로 환전, 이후 와넥스에서 와인 NFT 구입하는 과정을 거치게 되는것입니다.
              <br/><br/>
              이제 각각의 과정을 상세히 살펴 보겠습니다.
            </div>
            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step1/pc/1.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step1/mobile/1.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>1.</b>
                <p style='text-align:left'><a href="https://metamask.io/" target="_blank">https://metamask.io/</a>에 접속하여 다운로드 후 자동 실행을 기다린 후 메타마스크의 안내에 따라 지갑을 생성합니다.</p>
              </div>
            </div>
            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step1/pc/2.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step1/mobile/2.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>2.</b>
                <p>사용시 편리함을 위해 주소창 오른쪽의 확장프로그램을 누른 후 메타마스크 고정을 하면 더 쉽게 사용 가능합니다.</p>
              </div>
            </div>
            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step1/pc/3.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step1/mobile/3.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>3.</b>
                <p>메타마스크 생성 후 Winex 홈페이지 우측 상단의 커넥트 Connect를 눌러 폴리곤 메인넷 Polygon Mainnet을 추가 후 네트워크 전환을 합니다. 그리고 와넥스와 연결합니다.</p>
              </div>
            </div>
            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step1/pc/4.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step1/mobile/4.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>4.</b>
                <p>메타마스크를 실행 토큰 가져오기를 누른 후
                  토큰 계약 주소에 USDC 주소 <a class="highlight" id="usdc-address" @click="innerTextCopyBtn('usdc-address')">0x2791bca1f2de4661ed88a30c99a7a9449aa84174</a>
                  를 복사해서 넣으면 자동으로 USDC로 세팅이 되며,
                  그 후 맞춤형 토큰 추가 및 가져오기를 누르면
                  USDC 를 사용하실 수 있습니다.</p>
              </div>
            </div>

          </div>

        </div>
        <!-- //end Step1 -->
        <!-- 과거 Step2-1 -->
<!--        <div v-if="no === 2">-->
<!--          <div class="notice-subject-wrap">-->
<!--            <div class="notice-subject">Step 2-1. 폴리곤 메인넷 세팅 방법</div>-->
<!--            <div class="url-copy-btn" @click="urlCopyBtn">-->
<!--              URL 복사-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="user-guide-content-wrap">-->

<!--            <div class="user-guide-row">-->
<!--              <div class="user-guide-image-wrap">-->
<!--                <img src="~@/assets/images/user-guide/step2-1/pc/1.png" class="user-guide-pc-image">-->
<!--                <img src="~@/assets/images/user-guide/step2-1/mobile/1.png" class="user-guide-mobile-image">-->
<!--              </div>-->
<!--              <div class="user-guide-content-wrap">-->
<!--                <b>1. 우측상단의 Connect Wallet을 클릭</b>-->
<!--                <p><a href="https://chainlist.org/" target="_blank">https://chainlist.org/</a> 에 접속합니다. 그 후 우측 상단의 Connect Wallet을 클릭</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="user-guide-row">-->
<!--              <div class="user-guide-image-wrap">-->
<!--                <img src="~@/assets/images/user-guide/step2-1/pc/2.png" class="user-guide-pc-image">-->
<!--                <img src="~@/assets/images/user-guide/step2-1/mobile/2.png" class="user-guide-mobile-image">-->
<!--              </div>-->
<!--              <div class="user-guide-content-wrap">-->
<!--                <b>2. 다음 버튼 클릭</b>-->
<!--                <p>클릭 후 이 사이트와 연결할 것인지 물어봅니다. 다음버튼을 누릅니다.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="user-guide-row">-->
<!--              <div class="user-guide-image-wrap">-->
<!--                <img src="~@/assets/images/user-guide/step2-1/pc/3.png" class="user-guide-pc-image">-->
<!--                <img src="~@/assets/images/user-guide/step2-1/mobile/3.png" class="user-guide-mobile-image">-->
<!--              </div>-->
<!--              <div class="user-guide-content-wrap">-->
<!--                <b>3. 연결 버튼 클릭 후 Polygon 검색</b>-->
<!--                <p>연결 클릭 후 상단의 Search Networks 에 Polygon 검색을 합니다.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="user-guide-row">-->
<!--              <div class="user-guide-image-wrap">-->
<!--                <img src="~@/assets/images/user-guide/step2-1/pc/4.png" class="user-guide-pc-image">-->
<!--                <img src="~@/assets/images/user-guide/step2-1/mobile/4.png" class="user-guide-mobile-image">-->
<!--              </div>-->
<!--              <div class="user-guide-content-wrap">-->
<!--                <b>4. Add To Metamask 버튼 클릭</b>-->
<!--                <p>검색 후 나오는 Polygon Mainnet 아래의 Add To Metamask 버튼을 눌러줍니다.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="user-guide-row">-->
<!--              <div class="user-guide-image-wrap">-->
<!--                <img src="~@/assets/images/user-guide/step2-1/pc/5.png" class="user-guide-pc-image">-->
<!--                <img src="~@/assets/images/user-guide/step2-1/mobile/5.png" class="user-guide-mobile-image">-->
<!--              </div>-->
<!--              <div class="user-guide-content-wrap">-->
<!--                <b>5. 승인 버튼 클릭</b>-->
<!--                <p>네트워크 추가할 수 있도록 승인을 합니다. 승인 후 네트워크 전환 혹은 취소를 누릅니다.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
        <!-- //end 과거 Step2-1 -->

        <!-- 과거 Step2-2 -->
<!--        <div v-if="no === 3">-->
<!--          <div class="notice-subject-wrap">-->
<!--            <div class="notice-subject">Step 2-2. 폴리곤 기반 USDC 추가하기</div>-->
<!--            <div class="url-copy-btn" @click="urlCopyBtn">-->
<!--              URL 복사-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="user-guide-content-wrap">-->

<!--            <div class="user-guide-row">-->
<!--              <div class="user-guide-image-wrap">-->
<!--                <img src="~@/assets/images/user-guide/step2-2/pc/1.png" class="user-guide-pc-image">-->
<!--                <img src="~@/assets/images/user-guide/step2-2/mobile/1.png" class="user-guide-mobile-image">-->
<!--              </div>-->
<!--              <div class="user-guide-content-wrap">-->
<!--                <b>1. 우측상단 검색창에 USDC를 검색</b>-->
<!--                <p><a href="https://coinmarketcap.com/ko/" target="_blank">https://coinmarketcap.com/ko/</a> 접속 후 우측 상단의 검색에서 USDC를 검색합니다.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="user-guide-row">-->
<!--              <div class="user-guide-image-wrap">-->
<!--                <img src="~@/assets/images/user-guide/step2-2/pc/2.png" class="user-guide-pc-image">-->
<!--                <img src="~@/assets/images/user-guide/step2-2/mobile/2.png" class="user-guide-mobile-image">-->
<!--              </div>-->
<!--              <div class="user-guide-content-wrap">-->
<!--                <b>1. More버튼 클릭</b>-->
<!--                <p>검색 후 계약탭의 More를 눌러줍니다.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="user-guide-row">-->
<!--              <div class="user-guide-image-wrap">-->
<!--                <img src="~@/assets/images/user-guide/step2-2/pc/3.png" class="user-guide-pc-image">-->
<!--                <img src="~@/assets/images/user-guide/step2-2/mobile/3.png" class="user-guide-mobile-image">-->
<!--              </div>-->
<!--              <div class="user-guide-content-wrap">-->
<!--                <b>3. Polygon의 여우 아이콘 클릭</b>-->
<!--                <p>More를 누른 후 Polygon을 찾아 오른편의 여우 모양을 클릭합니다.-->
<!--                  (지갑네트워크를 변경해달라고 하는경우 Metamask 상단의 메인넷에서 Matic Mainnet으로 바꿔줍니다.)</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="user-guide-row">-->
<!--              <div class="user-guide-image-wrap">-->
<!--                <img src="~@/assets/images/user-guide/step2-2/pc/4.png" class="user-guide-pc-image">-->
<!--                <img src="~@/assets/images/user-guide/step2-2/mobile/4.png" class="user-guide-mobile-image">-->
<!--              </div>-->
<!--              <div class="user-guide-content-wrap">-->
<!--                <b>4. 토큰 추가버튼 클릭</b>-->
<!--                <p>추천 토큰 추가에서 토큰 추가버튼 클릭합니다.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="user-guide-row">-->
<!--              <div class="user-guide-image-wrap">-->
<!--                <img src="~@/assets/images/user-guide/step2-2/pc/5.png" class="user-guide-pc-image">-->
<!--                <img src="~@/assets/images/user-guide/step2-2/mobile/5.png" class="user-guide-mobile-image">-->
<!--              </div>-->
<!--              <div class="user-guide-content-wrap">-->
<!--                <b>5. 메타마스크 지갑에서 USDC가 있는지 확인</b>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->
        <!-- //end 과거 Step2-2 -->

        <!-- Step2 -->
        <div v-if="no === 4">
          <div class="notice-subject-wrap">
            <div class="notice-subject">Step 2 체인질리를 이용한 USDC구매하기</div>
            <div class="url-copy-btn" @click="urlCopyBtn">
              URL 복사
            </div>
          </div>
          <div class="user-guide-preview-content">
            <div>
              <b>[ 선행 과정 ]</b><br/>
              <p>
                메타마스크(폴리곤 네트워크 추가 및 폴리곤USDC추가) 업비트 가입 및 입금 및 내 지갑 연결 하기<br/>
                <br/>
                [ 메타마스크-업비트 연결 ]<br/>
                <a href="https://upbitcs.zendesk.com/hc/ko/articles/6713306957977" target="_blank">https://upbitcs.zendesk.com/hc/ko/articles/6713306957977</a><br/>
                주의 : 업비트는 첫 입금 후 72시간 이후,첫 거래 이후 24시간이 지난 후 출금 가능하므로 필요한 만큼 충분한 입금을 해둬야 합니다. 언제든 다시 송금 가능한 원화 계좌이므로 리스크는 없습니다.<br/>
              </p>
            </div>
            <div class="user-guide-preview-content-wrap">
              <b>1. 업비트에서 이더리움을 구매하여 메타마스크로 출금</b>
              <p>업비트에서 이더리움을 구매하여 메타마스크로 출금합니다. (1 이더리움을 내 메타마스크 지갑으로 보내는 것을 원하시면 업비트 수수료 0.018이더리움을 포함하여 1.018이상의 이더리움을 구매하시면 됩니다.)</p>
            </div>
          </div>
          <div class="user-guide-content-wrap" style="border-top: 1px solid #CBCBCB;">

            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step3/pc/1.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step3/mobile/1.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>2. Changelly 사이트에 접속 한글 변경 방법</b><br/>
                <p style='text-align:left'>
                  메타마스크에 이더리움이 들어온걸 확인 후<br/>
                  <a href="https://changelly.com/" target="_blank">[Changelly.com]</a>에 들어갑니다. (오른쪽 상단에서 태극기를 선택하시면 한글로 이용 가능합니다.)
                </p>
              </div>
            </div>

            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step3/pc/2.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step3/mobile/2.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>3. 통화 변경 후 환전</b><br/>
                <p style='text-align:left'>
                  체인질리Changelly 에서 보내는 통화를 이더리움(ETH)으로 바꾸고 받는 통화를 USDCMATIC 으로 바꾼 후 바꾸고 싶은 이더리움의 수량을 적어놓은 후 Exchange Now를 누릅니다. (USDC가 여러종류가 있는데 반드시 USDCMATIC으로 하셔야 합니다.)<br/>
                  <br/>
                  이더리움을 폴리곤 USDC로 교환해주는 이러한 서비스가 코인별 유동성이 부족하면 안될때도 있습니다. 이럴땐 유사한 다른 서비스인 체인지나우 <a href="https://changenow.io/" target="_blank">https://changenow.io/</a> 를 이용해보시기 바랍니다. 이용 방법은 체인질리와 매우 유사합니다. (USDC Polygon으로 교환하시면 됩니다.)
                </p>
              </div>
            </div>

            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step3/pc/3.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step3/mobile/3.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>4. Changelly 사이트에 접속 한글 변경 방법</b><br/>
                <p style='text-align:left'>
                  다음 페이지로 넘어오시면 Floating Rate(최고속도)와 Fixed Rate(고정 교환 비율) 가 있으며 변환되는 USDC갯수가 표기됩니다.(Fixed Rate로 하시는걸 권장드립니다.) 이후 아래에 USDC를 받을 내 메타마스크 지갑 주소를 적은 후 다음 단계로 넘어갑니다. (이더리움을 보낼때 가스비가 필요합니다. 그러므로 1이더리움만 있는 경우 가스비가 들어가므로 0.99이더리움을 보내시는것을 추천드립니다.)
                </p>
              </div>
            </div>
            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step3/pc/4.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step3/mobile/4.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>5. 최종 확인 후 버튼 클릭</b><br/>
                <p style='text-align:left'>
                  그 후 이메일을 적은 후 내가 적은 주소 및 변환할 이더리움 및 USDCMATIC 교환비를 확인 후 다음으로 넘어갑니다.
                </p>
              </div>
            </div>
            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step3/pc/5.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step3/mobile/5.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>6. 주소 복사하기 버튼 클릭 이더리움 메인넷에 보내기</b><br/>
                <p style='text-align:left'>
                  화면에서 보이는 체인질리 주소Changelly address (ETH)를 복사 합니다. 그 후 내 메타마스크의 이더리움 메인넷으로 들어가 보내기를 누른 후 복사 된 체인질리의 주소로 변환할 만큼의 이더리움을 적은 후 다음을 누르고 가스비를 확인한 후 보냅니다. 보내는 과정에서 5-10분 이상 걸리며 이더리움에서 USDCMATIC으로 변환 후 받는 과정에서 5-10분 이상 걸립니다. 그 동안 체인질리 웹사이트를 종료하지 마시고 변환이 끝나는 과정까지 놔두시면 됩니다.
                </p>
              </div>
            </div>
          </div>


        </div>
        <!-- //end Step2 -->

        <!-- Step4 -->
        <div v-if="no === 5">
          <div class="notice-subject-wrap">
            <div class="notice-subject">Step 3. 가스비로 사용할 MATIC 교환 및 가스비 설정하기</div>
            <div class="url-copy-btn" @click="urlCopyBtn">
              URL 복사
            </div>
          </div>
          <div class="user-guide-preview-content-wrap" style="background: none;">
            <b>가스비(Gas Fee)란 무엇인가요?</b><br/>
            <br/>
            <p>NFT는 블록체인에 기록되는 영구불변의 정보로, 블록체인 네트워크에 데이터를 주고받는 과정에서 네트워크 이용료를 내야합니다. 이를 가스비(Gas Fee)라 부르며, 이더리움 등 해당 블록체인에서 사용하는 가상자산으로 지불합니다. 와넥스는 폴리곤 기반 NFT를 이용하므로 폴리곤의 MATIC이 가스비로 필요합니다.</p><br/>
            <br/>
            <br/>
            <b>가스비로 사용할 메틱 교환하기</b><br/>
            <br/>
            <p>
            <a href="https://wallet.polygon.technology/polygon/token-swap" target="_blank">https://wallet.polygon.technology/polygon/token-swap</a><br/>
            1. 위 사이트에 접속 후 Connect  to a Wallet 을 누른 후 메타마스크 로그인을 합니다.<br/>
            2. Swap from. 의 셀렉트 토큰에서 메틱으로 변환할 USDC 토큰을 고릅니다.<br/>
            3. 변환 할 MATIC 갯수를 위에서 고릅니다.<br/>
            4. Approve를 누른 후 메타마스크의 서명을 합니다.<br/>
            (모든 서명은 메시지 스크롤을 끝까지 아래로 내리셔야 활성화 됩니다.)<br/>
            5. 그 후 Swap이 활성화 되면 누른 후 다시 한번 메타마스크의 서명을 한 후 기다리면 완료됩니다.
            </p><br/>
            <br/>
            <br/>
            <b>메타마스크 가스비 고급 설정</b><br/>
            <br/>
            <p>
            폴리곤 네트워크가 혼잡한 경우 구매시 오류가 나는 경우가 있습니다.<br/>
            이 경우에는 가스비(Gas Fee)를 올려서 내면 네트워크를 우선순위로 사용가능합니다.<br/>
            와넥스에서는 가스비 최대 요금 지불을 추천드립니다.
            </p>
          </div>
          <div class="user-guide-content-wrap">

            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step4/pc/1.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step4/mobile/1.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>1. 메타마스크의 우측 상단에서 설정을 누릅니다.</b>
              </div>
            </div>

            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step4/pc/2.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step4/mobile/2.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>2. 설정에서 두번째 고급 탭으로 들어갑니다.</b>
              </div>
            </div>

            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step4/pc/3.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step4/mobile/3.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>3. 고급 가스 제어 기능을 켜기</b>
                <p>고급탭의 아래 부분에서 고급 가스 제어 기능을 켭니다.</p>
              </div>
            </div>

            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step4/pc/4.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step4/mobile/4.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>4. 가스비 UI 활성화 켜기</b>
                <p>다시 설정으로 돌아와 실험적을 누른 후 향상된 가스비 UI활성화를 켭니다.</p>
              </div>
            </div>

            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step4/pc/5.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step4/mobile/5.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>5. 설정 후 NFT 구매 시 가스의 시장을 누른 후 가스요금 편집의 고급을 누릅니다.</b>
              </div>
            </div>

            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step4/pc/6.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step4/mobile/6.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>6. 최대 기본요금과 우선요금을 300으로 적은 후 아래 내용에 체크를 한 후 저장을 합니다. </b>
              </div>
            </div>

            <div class="user-guide-row">
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/user-guide/step4/pc/7.png" class="user-guide-pc-image">
                <img src="~@/assets/images/user-guide/step4/mobile/7.png" class="user-guide-mobile-image">
              </div>
              <div class="user-guide-content-wrap">
                <b>7. 그 후 가스요금을 확인한 후 확인을 누르고 거래를 진행하시면 됩니다.</b>
                <p>이 이후 거래시에는 따로 설정할 필요 없이 현재 설정이 기본값이 되며, 가스비가 너무 많이 나오는 경우가 있기에 항상 요금을 확인해야합니다. 가스비가 너무 많이 나오는경우 가스 요금 편집을 눌러 공격적을 누르는 방법도 있습니다.</p>
              </div>
            </div>

          </div>
        </div>
        <!-- end Step4 -->
        <div class="nav" v-bind:style="{ 'justify-content' : (no === 1 || no === 4) ? 'space-around' : 'space-between' }">
          <!-- Prev-btn -->
          <div class="left-btn" @click="navClick(1)" v-if="no === 4">
            <div class="icon"></div>
            <div class="text">
              <div class="title">Step 1</div>
              <div class="content">메타마스크 생성 및 세팅</div>
            </div>
          </div>
<!--          <div class="left-btn" @click="navClick(2)" v-if="no === 3">
            <div class="icon"></div>
            <div class="text">
              <div class="title">Step 2-1</div>
              <div class="content">폴리곤 메인넷 세팅 방법</div>
            </div>
          </div>
          <div class="left-btn" @click="navClick(3)" v-if="no === 4">
            <div class="icon"></div>
            <div class="text">
              <div class="title">Step 2-2</div>
              <div class="content">폴리곤 기반 USDC 추가하기</div>
            </div>
          </div>-->
          <div class="left-btn" @click="navClick(4)" v-if="no === 5">
            <div class="icon"></div>
            <div class="text">
              <div class="title">Step 2</div>
              <div class="content">체인질리를 이용한 USDC구매하기</div>
            </div>
          </div>
          <!-- //end Prev-btn -->
          <!-- Next-btn -->
<!--          <div class="right-btn" @click="navClick(2)" v-if="no === 1">
            <div class="text">
              <div class="title">Step 2-1</div>
              <div class="content">폴리곤 메인넷 세팅 방법</div>
            </div>
            <div class="icon"></div>
          </div>
          <div class="right-btn" @click="navClick(3)" v-if="no === 2">
            <div class="text">
              <div class="title">Step 2-2</div>
              <div class="content">폴리곤 기반 USDC 추가하기</div>
            </div>
            <div class="icon"></div>
          </div>-->
          <div class="right-btn" @click="navClick(4)" v-if="no === 1">
            <div class="text">
              <div class="title">Step 2</div>
              <div class="content">체인질리를 이용한 USDC구매하기</div>
            </div>
            <div class="icon"></div>
          </div>
          <div class="right-btn" @click="navClick(5)" v-if="no === 4">
            <div class="text">
              <div class="title">Step 3</div>
              <div class="content">가스비로 사용할 MATIC 교환 및 가스비 설정하기</div>
            </div>
            <div class="icon"></div>
          </div>
          <!-- //end Next-btn -->
        </div>

      </div>

    </div>
    <div class="notice-footer">
      <ul>
        <li @click="moveList">
          <div class="prev-notice notice-footer-btn">이용가이드 목록 보기</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>


export default {
  name: "GuideDetail",
  data() {
    return {
      no : Number
    }
  },
  created() {
    this.no = Number(this.$route.params.no);
    console.log(this.no);
  },
  methods: {
    navClick(no) {
      this.$router.push(
          {
            path : '/cs/guide/detail/' + no
          }
      );
    },
    urlCopyBtn() {
      var url = '';
      var textarea = document.createElement("textarea");
      document.body.appendChild(textarea);
      url = window.document.location.href;
      textarea.value = url;
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      alert('링크가 복사 되었습니다.')
    },
    innerTextCopyBtn(el) {
      const valOfDIV = document.getElementById(el).innerText;

      // textarea 생성
      const textArea = document.createElement('textarea');

      // textarea 추가
      document.body.appendChild(textArea);

      // textara의 value값으로 div내부 텍스트값 설정
      textArea.value = valOfDIV;

      // textarea 선택 및 복사
      textArea.select();
      document.execCommand('copy');

      // textarea 제거
      document.body.removeChild(textArea);

      alert('주소가 복사 되었습니다.')
    },
    moveList() {
      this.$router.push(
          {
            path : '/cs/guide'
          }
      );
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/guide-detail.scss";
</style>
<!-- 에디터 스타일 (에디터쪽 view 스타일을 적용하면 됩니다 ~ 나중에 CMS에서 어떤 에디터를 쓸 줄 모르는 상황) -->
<style lang="scss">
.notice-content-wrap {
  img {
    width: 100%;
    margin-bottom:8px;
  }
  a {
    font-weight: bold;
    text-decoration: underline;
  }
}

.pc-notice-content {
  img {
    max-width: 100%;
  }

  p img {
    max-width: 100%;
  }
}

.mobile-notice-content {
  img {
    max-width: 100%;
  }

  p img {
    max-width: 100%;
  }
}

.line {
  border-bottom: 1px solid #ccc;
  margin: 22px 0;
}

</style>
